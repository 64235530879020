import React from "react"
import Layout from "@/components/layout"
import FormContent from "@/components/matriculasForm"
import Footer from "@/components/footer"

const MatriculasForm = () => {
  return (
    <Layout>
      <FormContent />
      <Footer />
    </Layout>
  );
};

export default MatriculasForm;
